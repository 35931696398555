<template>
  <tr style="border-bottom: solid grey 1px">
    <td>
      <img :src="avatar" class="spacing" style="width: 5em; height: 5em" />
    </td>
    <td>
      <div class="spacing">
        {{ name }}<br /><small>({{ id }})</small>
      </div>
    </td>
    <td>
      <div class="spacing flexcolumn">
        <div class="flexrow">
          <div class="flexcolumn">
            <div>
              Bez PDV-a: <b>{{ woPdv }}</b> * {{ quantity }}
            </div>
            <div>
              Sa PDV-om: <b>{{ wPdv }}</b> * {{ quantity }}
            </div>
          </div>
          <div class="flexcolumn" style="margin-left: 1em">
            <div>= {{ woPdvQ }}</div>
            <div>= {{ wPdvQ }}</div>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div class="spacing flexrow jcenter acenter">
        <button class="qBtn" @click="changeQuantity(false)">-</button>
        <div style="margin: 0.5em">
          {{ quantity }}
        </div>
        <button class="qBtn" @click="changeQuantity(true)">+</button>
      </div>
    </td>
    <td><div @click="remove" class="spacing remove pointer" /></td>
  </tr>
</template>

<script>
import axios from "../config/axios";

export default {
  name: "CartItem",
  props: {
    id: String,
    name: String,
    quantity: Number,
    wPdv: Number,
    woPdv: Number,
  },
  methods: {
    async changeQuantity(increment) {
      let params = {
        ATOKEN: this.$store.state.user.SESSION_TOKEN,
        ARTIKAL: this.id,
        KOLICINA: increment ? 1 : -1,
      };
      const res = (await axios.post("AddToCart", null, { params })).data;
      this.$store.state.cartCount = res.BROJ_ARTIKALA_U_KORPI;
      sessionStorage.setItem(
        "BROJ_ARTIKALA_U_KORPI",
        res.BROJ_ARTIKALA_U_KORPI
      );
      if (parseInt(res.DODATA_KOLICINA) !== (increment ? 1 : -1)) {
        alert(
          increment
            ? "Količina proizvoda se nije promenila jer trenutno nije na stanju"
            : "Količina ne sme biti manja od nule"
        );
      }
      this.$emit("refresh");
    },
    async remove() {
      const check = confirm(
        `Da li ste sigurni da želite da uklonite "${this.name}" iz korpe.`
      );
      if (!check) return;
      let params = {
        ATOKEN: this.$store.state.user.SESSION_TOKEN,
        ARTIKAL: this.id,
      };
      const res = (await axios.post("RemoveCartItem", null, { params })).data;
      this.$store.state.cartCount = res.BROJ_ARTIKALA_U_KORPI;
      sessionStorage.setItem(
        "BROJ_ARTIKALA_U_KORPI",
        res.BROJ_ARTIKALA_U_KORPI
      );
      this.$emit("refresh");
    },
  },
  computed: {
    avatar() {
      return axios.defaults.baseURL + "/GetImage?ARTIKAL=" + this.id;
    },
    wPdvQ() {
      return (this.wPdv * this.quantity).toFixed(2);
    },
    woPdvQ() {
      return (this.woPdv * this.quantity).toFixed(2);
    },
  },
};
</script>

<style scoped>
.spacing {
  margin: 0 1em;
}
.remove {
  height: 1.8em;
  width: 1.8em;
  background-image: url("../assets/trash.svg");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}
.qBtn {
  font-size: 1.25em;
  font-weight: bold;
}
</style>