import Vue from 'vue';
import VueRouter from 'vue-router';
import Products from '../pages/Products';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Products',
    component: Products
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   if (from.name === 'Advertisement' && to.name === 'Home') {
//     sessionStorage.setItem('returningToHome', true);
//     setTimeout(() => sessionStorage.removeItem('returningToHome'), 1000);
//   }
//   next();
// });

export default router;
