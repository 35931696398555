<template>
  <div id="app">
    <Modals />
    <div id="root" class="flexcolumn between" v-if="ready">
      <!-- <div class="flexcolumn between" v-if="$store.state.user"> -->
      <div>
        <MyHeader />
        <SearchBar />
        <div style="padding: 0 7%"><router-view /></div>
      </div>
      <MyFooter />
    </div>
    <!-- <div class="signin">
      <SigninPage />
    </div>
  </div> -->
  </div>
</template>

<script>
// import SigninPage from './components/SigninPage';
import Modals from "./components/modals/Modals";
import MyHeader from "./components/MyHeader";
import MyFooter from "./components/MyFooter";
import SearchBar from "./components/SearchBar";
import autobus from "./config/autobus";

export default {
  name: "app",
  components: {
    Modals,
    MyHeader,
    MyFooter,
    SearchBar,
    // SigninPage
  },
  data() {
    return {
      ready: false,
    };
  },
  created() {
    if (sessionStorage.getItem("user")) {
      this.$store.state.user = JSON.parse(sessionStorage.getItem("user"));
      this.$store.state.cartCount = sessionStorage.getItem("BROJ_ARTIKALA_U_KORPI");
    }
    this.ready = true;
    autobus.$on("signout", () => {
      this.$store.state.user = null;
      sessionStorage.removeItem("user");
      this.$router.push("/");
    });
  },
  methods: {},
};
</script>

<style>
@import "./config/global.css";
#root {
  min-height: 100vh;
}
</style>
