<template>
  <div class="productspane flexcolumn">
    <ProductsFilter @search="search" />
    <div v-if="$store.state.display === 'tiles'" class="tiles flexrow flexwrap">
      <ProductTile
        v-for="p in products"
        :key="p.id"
        :naziv="p.name"
        :kod="p.id"
        :kolicina="p.lager"
        :kriticnakolicina="p.cKol"
        :nominalna="p.nCena"
        :neto="p.vCenaN"
        :bruto="p.vCenaB"
        :brSlika="p.brSlika"
      />
    </div>
    <table style="border-collapse: collapse" v-else>
      <tr>
        <th></th>
        <th>Naziv</th>
        <th>Cene</th>
        <!-- <template v-if="user"> -->
          <th>Količina</th>
          <th></th>
          <th></th>
        <!-- </template> -->
      </tr>
      <ProductList
        v-for="p in products"
        :key="p.id"
        :naziv="p.name"
        :kod="p.id"
        :kolicina="p.lager"
        :kriticnakolicina="p.cKol"
        :nominalna="p.nCena"
        :neto="p.vCenaN"
        :bruto="p.vCenaB"
        :brSlika="p.brSlika"
      />
    </table>
  </div>
</template>

<script>
import autobus from "../../config/autobus";
import axios from "../../config/axios";
import ProductsFilter from "./ProductsFilter";
import ProductTile from "./ProductTile";
import ProductList from "./ProductList";

export default {
  name: "ProductsPane",
  components: { ProductsFilter, ProductTile, ProductList },
  data() {
    return {
      products: [],
      lastRequest: {},
    };
  },
  created() {
    this.getProducts({ search: "global", anew: true });
    autobus.$on("search", this.getProducts);
  },
  destroyed() {
    autobus.$off("search");
  },
  methods: {
    search() {
      this.display = d;
    },
    async getProducts(t) {
      let params = {};
      if (t.search === "category")
        params.KATEGORIJA = this.$store.state.category;
      if (t.keyword && t.search === "category")
        params.KEYWORD = this.$store.state.categorySearch;
      else if (t.keyword && t.search === "global")
        params.KEYWORD = this.$store.state.globalSearch;
      else if ((t.search === "refresh")) params = this.lastRequest;
      this.lastRequest = params;
      if (this.user) params.ATOKEN = this.user.SESSION_TOKEN;
      if (t.anew) this.$store.state.page = 1;
      params.STRANA = this.$store.state.page;
      params.SORT_BY = this.$store.state.sort;
      const response = (await axios.get("GetProducts", { params })).data;
      this.$store.state.pages = response.BR_STRANA;
      this.products = response.ARTIKLI;
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    category() {
      return this.$store.state.category;
    },
  },
  watch: {
    category() {
      this.getProducts({ search: "category", anew: true });
    },
  },
};
</script>

<style scoped>
.productspane {
  margin: 0.5em;
}
</style>
