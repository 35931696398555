<template>
  <div class="cartico pointer flexrow acenter" @click="openCart">
    <div class="cart" />
    <div
      class="cartcount flexrow acenter jcenter"
      v-if="$store.state.cartCount"
    >
      <div class="carttext" v-text="$store.state.cartCount" />
    </div>
  </div>
</template>

<script>
import autobus from "../config/autobus";

export default {
  name: "CartIco",
  methods: {},
  methods: {
    openCart() {
      autobus.$emit("modal", { modal: "cartOrder" });
    },
  },
};
</script>

<style scoped>
.cartico {
  position: relative;
}

.cart {
  height: 4em;
  width: 4em;
  background-image: url("../assets/cart.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.cartcount {
  min-height: 1.3em;
  min-width: 1.3em;
  position: absolute;
  bottom: 0.5em;
  right: -0.4em;
  background-color: #e05c0b;
  border-radius: 50%;
}

.carttext {
  color: white;
  text-align: center;
}
</style>
