<template>
  <div class="products flexrow">
    <div class="flexcolumn">
      <Category
        v-for="category in categories"
        :key="category.id"
        :indent="0"
        :category="category"
      />
    </div>
    <div class="products"><ProductsPane /></div>
  </div>
</template>

<script>
import axios from "../config/axios";
import ProductsPane from "../components/Products/ProductsPane";
import Category from "../components/Category";

export default {
  name: "Products",
  components: { ProductsPane, Category },
  data() {
    return {
      categories: [],
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      try {
        this.categories = (await axios.get("GetCategories")).data;
        this.$store.state.category = this.categories[0].id;
      } catch (e) {
        console.error(e);
        setTimeout(this.getCategories, 1000);
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style scoped>
.products {
  flex: 1;
}
</style>
