<template>
  <div>
    <CartOrder v-if="cartOrder.show" @closeMe="cartOrder.show = false" />
    <Signin
      v-if="signin.show"
      @closeMe="signin.show = false"
      :onSignin="signin.onSignin"
    />
    <ProductDetails
      v-if="productDetails.show"
      @closeMe="productDetails.show = false"
      :id="productDetails.id"
      :name="productDetails.name"
      :brSlika="productDetails.brSlika"
      :price="productDetails.price"
    />
  </div>
</template>

<script>
import Signin from "./Signin";
import CartOrder from "./CartOrder";
import ProductDetails from "./ProductDetails";
import autobus from "../../config/autobus";

export default {
  name: "Modals",
  components: {
    Signin,
    ProductDetails,
    CartOrder,
  },
  data() {
    return {
      signin: {
        show: false,
        onSignin: null,
      },
      cartOrder: {
        show: false,
      },
      productDetails: {
        show: false,
        id: "",
        name: "",
        brSlika: 0,
        price: {},
      },
    };
  },
  created() {
    autobus.$on("modal", (d) => {
      Object.keys(d)
        .filter((x) => x !== "modal" && x !== "show")
        .forEach(
          (x) => this[d.modal][x] !== undefined && (this[d.modal][x] = d[x])
        );
      if (d.show === undefined) this[d.modal].show = true;
      else this[d.modal].show = d.show;
    });
  },
};
</script>

<style>
</style>