<template>
  <div class="searchbar flexcolumn">
    <div class="flexrow acenter" style="position: relative">
      <div class="logo pointer" />
      <div class="searchholder" style="margin-left: 6em; width: 35em">
        <input
          v-model="$store.state.globalSearch"
          v-on:keyup.enter="search"
          placeholder="Pretraga proizvoda"
          class="search"
          type="text"
        />
        <div class="searchbutton pointer" @click="search" />
      </div>
      <div v-if="user" class="rightside flexrow acenter">
        <div class="favorite pointer" />
        <div class="separator">|</div>
        <CartIco />
      </div>
    </div>
  </div>
</template>

<script>
import autobus from "../config/autobus";
import CartIco from "./CartIco";

export default {
  name: "SearchBar",
  components: {
    CartIco,
  },
  methods: {
    signout() {
      autobus.$emit("signout");
    },
    search() {
      autobus.$emit("search", { search: "global", anew: true, keyword: true });
    },
  },
  data() {
    return {
      signoutVisible: false,
    };
  },
  computed: {
    nav() {
      return this.$store.state.nav;
    },
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style scoped>
.searchbar {
  position: relative;
  background-color: #f2f2f2;
  padding: 2em 7%;
  margin-bottom: 0.5em;
}

.logo {
  width: 10em;
  height: 5em;
  background-image: url("../assets/logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.searchholder {
  position: relative;
  height: 3.2em;
}

.search {
  font-size: unset;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: calc(100% - 3.2em);
  position: absolute;
  border: none;
  border-radius: 1em;
  padding-right: 3.2em;
  padding-left: 0.5em;
}

.searchbutton {
  position: absolute;
  right: 0;
  top: 0;
  height: 3em;
  width: 3em;
  margin-top: 0.1em;
  background-image: url("../assets/search.svg");
  background-position: center;
  background-size: 1.8em;
  background-repeat: no-repeat;
}

.rightside {
  margin-left: auto;
}

.separator {
  font-size: 1.5em;
  margin: 0 0.5em;
}

.favorite {
  height: 4em;
  width: 4em;
  background-image: url("../assets/favorite.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

textarea:focus,
input:focus {
  outline: none;
}
</style>
