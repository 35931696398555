<template>
  <div id="cartOrderModal" class="modal">
    <div class="modal-content flexcolumn">
      <div class="modal-header flexrow acenter between">
        <p style="color: white; font-weight: bold">Korpa</p>
        <div class="flexrow acenter" style="margin: 0 2em">
          <div style="margin: 0 1em">
            Ukupan iznos naručene robe (bez PDV-a/sa PDV-om): {{ sum }}
          </div>
          <div>
            <button :disabled="!items.length" @click="order" class="biginput">
              Naruči
            </button>
          </div>
          <button style="margin: 0 1em" class="biginput" @click="clear">
            Isprazni korpu
          </button>
          <span @click="$emit('closeMe')" class="closecartOrderModal"
            >&times;</span
          >
        </div>
      </div>
      <div class="modal-body">
        <div class="flexcolumn modal-body-root">
          <div class="cartItems">
            <table style="border-collapse: collapse; width: 100%">
              <tr>
                <th></th>
                <th>Naziv</th>
                <th>Cene</th>
                <th>Količina</th>
                <th>Brisanje</th>
              </tr>
              <CartItem
                ref="cartItem"
                v-for="item in items"
                :key="item.id"
                :id="item.id"
                :name="item.name"
                :quantity="item.kol"
                :wPdv="item.vCenaB"
                :woPdv="item.vCenaN"
                @refresh="getItems"
              />
              <!-- <CartItem
                ref="cartItem"
                v-for="item in items"
                :key="item.id"
                :id="item.id"
                :name="item.name"
                :quantity="item.quantity"
                :wPdv="item.wPdv"
                :woPdv="item.woPdv"
              /> -->
            </table>
          </div>
          <!-- <div class="order flexrow" style="justify-content: flex-end">
            <button class="biginput orderbtn">Naruči</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from "../CartItem";
import axios from "../../config/axios";

export default {
  name: "cartOrder",
  components: {
    CartItem,
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    getItems() {
      axios
        .get("GetCartItems", { params: { ATOKEN: this.user.SESSION_TOKEN } })
        .then((x) => (this.items = x.data));
    },
    async clear() {
      const check = confirm(
        "Da li ste sigurni da želite da ispraznite korpu?"
      );
      if (!check) return;
      const res = (
        await axios.post("ClearCart", null, {
          params: { ATOKEN: this.user.SESSION_TOKEN },
        })
      ).data;
      this.$store.state.cartCount = res.BROJ_ARTIKALA_U_KORPI;
      this.getItems();
    },
    order() {
      const check = confirm(
        "Da li ste sigurni da želite da naručite ovu robu?"
      );
      if (!check) return;
      axios
        .post("FinalizeCart", null, {
          responseType: "blob",
          params: { ATOKEN: this.user.SESSION_TOKEN },
        })
        .then((x) => {
          const pdf = new Blob([x.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(pdf);
          window.open(fileURL);
          this.$store.state.cartCount = 0;
          this.$emit("closeMe");
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    sum() {
      let woPdv = 0;
      let wPdv = 0;
      this.items.forEach((x) => (woPdv += x.vCenaN * x.kol));
      this.items.forEach((x) => (wPdv += x.vCenaB * x.kol));
      return woPdv.toFixed(2) + " / " + wPdv.toFixed(2);
    },
  },
  mounted() {
    for (let i = 0; i < this.brSlika; i++)
      this.images.push(
        `${axios.defaults.baseURL}/GetImage?ARTIKAL=${this.id}&RB=${i}`
      );
    const modal = document.getElementById("cartOrderModal");
    modal.style.display = "block";
    window.onclick = (event) => event.target == modal && this.$emit("closeMe");
    this.getItems();
  },
};
</script>

<style scoped>
p {
  color: black;
}
.border {
  border: 1px solid #ced4da;
  width: 4em;
  height: 1.8em;
  margin: 0.75em 0 1em 0;
}
.cartItems {
  flex: 1;
  overflow: auto;
}
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* The Close Button */
.closecartOrderModal {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closecartOrderModal:hover,
.closecartOrderModal:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* Modal Header */
.modal-header {
  padding: 2px 16px;
  background-color: #e05c0b;
  color: white;
}

/* Modal Body */
.modal-body {
  padding: 2px 16px;
  margin: 2em;
  position: relative;
  height: 100%;
}

.modal-body-root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
}
/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 70vw;
  height: 80vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
  top: 50%;
  transform: translateY(-50%);
}

/* Add Animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
</style>