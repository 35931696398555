<template>
  <div class="myheader flexcolumn jcenter">
    <div v-if="user" class="flexrow">
      <div style="margin-right: 1em">
        <i>({{ $store.state.user.USER_NAME }})</i>
      </div>
      <div class="pointer">MOJ NALOG</div>
      <div class="separator">|</div>
      <div class="pointer" @click="signout">ODJAVA</div>
    </div>
    <div v-else class="flexrow">
      <!-- <div class="pointer">REGISTRACIJA</div>
      <div class="separator">|</div> -->
      <div class="pointer" @click="showSignin">PRIJAVA</div>
    </div>
  </div>
</template>

<script>
import autobus from "../config/autobus";

export default {
  name: "MyHeader",
  methods: {
    signout() {
      autobus.$emit("signout");
    },
    showSignin() {
      autobus.$emit("modal", { modal: 'signin' });
    },
  },
  data() {
    return {
      signoutVisible: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style scoped>
.myheader {
  align-items: flex-end;
  background-color: #e05c0b;
  /* width: 100%; */
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  padding: 1em 4em;
}

.separator {
  vertical-align: middle;
  color: white;
  font-weight: bold;
  font-size: 1em;
  margin: 0 0.8em;
}
</style>
