<template>
  <div id="signinModal" class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <span @click="closeMe" class="closeSignInModal">&times;</span>
        <p style="color: white">Prijava</p>
      </div>
      <div class="modal-body">
        <div class="flexcolumn jcenter">
          <div class="flexcolumn">
            <div class="flexrow">
              <p>E-mail</p>
            </div>
            <div>
              <input
                ref="emailField"
                v-model="email"
                type="text"
                @keyup.enter="signin"
                class="biginput"
              />
            </div>
            <div class="flexrow">
              <p>Lozinka</p>
            </div>
            <div>
              <input
                v-model="password"
                type="password"
                @keyup.enter="signin"
                class="biginput"
              />
            </div>
          </div>
        </div>
        <button class="button signinButton" @click="signin">
          Prijavite se
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../config/axios";
import autobus from "../../config/autobus";

export default {
  name: "Signin",
  props: { onSignin: Function },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  mounted() {
    const modal = document.getElementById("signinModal");
    modal.style.display = "block";
    window.onclick = (event) => {
      if (event.target == modal) {
        this.closeMe();
      }
    };
    setTimeout(() => this.$refs.emailField.focus(), 500);
  },
  methods: {
    closeMe() {
      this.$emit("closeMe");
    },
    signin() {
      if (!this.email.trim() || !this.password.trim())
        return alert("E-mail i lozinka ne smeju biti prazni");
      const params = { user: this.email, pass: this.password };
      axios
        .post("UserAuth", null, { params })
        .then((d) => {
          sessionStorage.setItem("user", JSON.stringify(d.data));
          this.$store.state.user = d.data;
          this.$store.state.cartCount = d.data.BROJ_ARTIKALA_U_KORPI;
          sessionStorage.setItem(
            "BROJ_ARTIKALA_U_KORPI",
            d.data.BROJ_ARTIKALA_U_KORPI
          );
          autobus.$emit("search", { search: "refresh" });
          this.closeMe();
          if (this.onSignin)
            this.onSignin(this.$store.state.user.SESSION_TOKEN);
        })
        .catch((e) => alert("Loš E-mail ili lozinka"));
    },
  },
};
</script>

<style scoped>
.signinButton {
  width: 100%;
  margin-top: 0.5em;
}

.modal-footer {
  justify-content: flex-end;
}

p {
  color: black;
}
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.closeSignInModal {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closeSignInModal:hover,
.closeSignInModal:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* Modal Header */
.modal-header {
  padding: 2px 16px;
  background-color: #e05c0b;
  color: white;
}

/* Modal Body */
.modal-body {
  padding: 2px 16px;
  margin: 2em;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
</style>