import Vue from 'vue';
import App from './App.vue';
import Vuex from 'vuex';
import router from './router';

Vue.config.productionTip = false;

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    nav: 'products',
    search: {},
    cart: [],
    // Product related
    page: 1,
    pages: 10,
    cartCount: 1,
    sort: 'sifra',
    category: 0,
    display: localStorage.getItem('display') || 'list',
    categorySearch: '',
    globalSearch: ''
  }
});

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
