<template>
  <div id="productDetailsModal" class="modal">
    <div class="modal-content flexcolumn">
      <div class="modal-header">
        <span @click="$emit('closeMe')" class="closeproductDetailsModal"
          >&times;</span
        >
        <p style="color:white;font-weight:bold">{{ name }}</p>
      </div>
      <div class="modal-body">
        <div class="flexrow" style="position:absolute;top:0;bottom:0;left:0;right:0;height:100%">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                ref="sliderRefs"
                v-for="i in images"
                :key="i"
                class="swiper-slide"
                @click="enlarge(i)"
              >
                <div
                  :style="{ backgroundImage: `url(${i})` }"
                  class="swiper-image"
                >
                  <img style="height: 100%; opacity: 0" :src="i" />
                </div>
              </div>
            </div>
            <template v-if="images.length > 1">
              <div class="swiper-pagination"></div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
              <div class="swiper-scrollbar"></div>
            </template>
          </div>
          <div class="vr" style="margin: 0.5em;height:100%" />
          <div class="rightContainer flexcolumn">
            <div class="flexrow acenter jcenter">
              <h2>Opis</h2>
            </div>
            <div
              class="description"
              style="flex: 1; overflow: auto"
              v-text="description"
            />
            <div class="hr" style="margin:1em" />
            <div class="flexrow between">
              <div class="flexcolumn jcenter">
                <div class="flexrow">
                  <div style="margin-right:1em">Veleprodajna cena:</div>
                  <div class="space" v-text="price.nominalna.toFixed(2)" />
                </div>
                <div v-if="user" class="flexrow" style="font-weight: bold">
                  <div style="margin-right:1em">Vaša cena bez pdv-a:</div>
                  <div class="space" v-text="price.neto.toFixed(2)" />
                </div>
                <div class="flexrow">
                  <div style="margin-right:1em">{{ user ? "Vaša cena sa pdv-om" : "Cena sa pdv-om" }}:</div>
                  <div class="space" v-text="price.bruto.toFixed(2)" />
                </div>
              </div>
              <div class="cartHolder">
                <Cart :id="id" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cart from "../Cart";
import axios from "../../config/axios";

export default {
  name: "ProductDetails",
  props: {
    id: String,
    name: String,
    brSlika: Number,
    price: Object,
  },
  components: {
    Cart
  },
  data() {
    return {
      images: [],
      description: "",
      swiper: null
    };
  },
  methods: {
    enlarge(i) {
      window.open(i);
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    for (let i = 0; i < this.brSlika; i++)
      this.images.push(
        `${axios.defaults.baseURL}/GetImage?ARTIKAL=${this.id}&RB=${i}`
      );
    const modal = document.getElementById("productDetailsModal");
    modal.style.display = "block";
    window.onclick = (event) => {
      if (event.target == modal) {
        this.$emit("closeMe");
      }
    };
    axios
      .get("GetProductDetails", { params: { ARTIKAL: this.id } })
      .then((x) => (this.description = x.data.OPIS));
    setTimeout(() => {
      this.swiper = new Swiper(".swiper-container", {
        direction: "horizontal",
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });
      setTimeout(this.swiper.destroy, 4000);
    }, 500);
  },
  beforeDestroy() {
    this.swiper.destroy();
  },
};
</script>

<style scoped>
.swiper-container {
  flex: 1;
}
.rightContainer {
  flex: 1;
}
.swiper-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: inherit;
}
p {
  color: black;
}
.description {
  white-space: pre-wrap;
}
.border {
  border: 1px solid #ced4da;
  width: 4em;
  height: 1.8em;
  margin: 0.75em 0 1em 0;
}
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.closeproductDetailsModal {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closeproductDetailsModal:hover,
.closeproductDetailsModal:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* Modal Header */
.modal-header {
  padding: 2px 16px;
  background-color: #e05c0b;
  color: white;
}

/* Modal Body */
.modal-body {
  padding: 2px 16px;
  margin: 2em;
  position: relative;
  height: 100%;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80vw;
  height: 80vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
</style>