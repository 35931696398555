<template>
  <div class="productsfilter flexrow between">
    <div class="flexrow acenter">
      <div class="border ico tilesico" @click="setDisplay('tiles')" />
      <div class="border ico listico" @click="setDisplay('list')" />
      <div class="border searchholder">
        <input
          v-model="$store.state.categorySearch"
          @keyup.enter="search(false, true, true)"
          placeholder="Pretraga u kategoriji"
          class="search"
          type="text"
        />
        <div class="searchbutton pointer" @click="search(false, true, true)" />
      </div>
    </div>
    <div class="flexrow acenter">
      <div class="select">
        <select
          v-model="$store.state.sort"
          @change="search(true, false, false)"
          class="realselect flexrow acenter jcenter"
        >
          <option value="sifra">Šifra</option>
          <option value="naziv">Naziv</option>
          <!-- <option value="cenarastuca">Cena rastuća</option>
          <option value="cenaopadajuca">Cena opadajuća</option>
          <option value="novije">Novije</option> -->
        </select>
      </div>
      <div class="pages flexrow acenter">
        <div
          v-if="$store.state.page > 1"
          class="border ico leftico"
          @click="changePage('-')"
        />
        <div
          class="border flexrow acenter"
          style="margin: 0; width: auto; padding: 0 0.25em"
        >
          <div
            style="font-weight: bold; color: #e05c0b"
            @click="startPageEdit"
            v-show="!editingPage"
          >
            {{ $store.state.page }}
          </div>
          <div v-show="editingPage">
            <input
              class="pageinput"
              v-model="editingPageNumber"
              @blur="endPageEdit"
              ref="pageInput"
              type="number"
              min="1"
              :max="$store.state.pages"
            />
          </div>
          <div style="margin: 0 0.25em">OD</div>
          <div>{{ $store.state.pages }}</div>
        </div>
        <div
          v-if="$store.state.page < $store.state.pages"
          class="border ico rightico"
          @click="changePage('+')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import autobus from "../../config/autobus";

export default {
  name: "ProductsFilter",
  data() {
    return {
      editingPage: false,
      editingPageNumber: 0,
    };
  },
  created() {},
  methods: {
    search(refresh, anew, keyword) {
      autobus.$emit("search", {
        search: refresh ? "refresh" : "category",
        anew,
        keyword,
      });
    },
    setDisplay(d) {
      this.$store.state.display = d;
      localStorage.setItem("display", d);
    },
    changePage(p) {
      if (p === "-") this.$store.state.page = this.$store.state.page - 1;
      else if (p === "+" && this.$store.state.page < this.$store.state.pages)
        this.$store.state.page = this.$store.state.page + 1;
      else if (p !== this.$store.state.page && p <= this.$store.state.pages)
        this.$store.state.page = p;
      this.search(true, false, false);
    },
    startPageEdit() {
      this.editingPageNumber = this.$store.state.page;
      this.editingPage = true;
      setTimeout(() => this.$refs.pageInput.focus(), 250);
    },
    endPageEdit() {
      const page = this.editingPageNumber;
      if (!isNaN(page) && page <= this.$store.state.pages) {
        this.$store.state.page = parseInt(page);
        this.editingPage = false;
        this.search(true, false, false);
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style scoped>
.productsfilter {
  margin-bottom: 0.5em;
}

.border {
  border: 1px solid #ced4da;
  width: 1.8em;
  height: 1.8em;
  cursor: pointer;
  margin-left: 0.25em;
}

.ico {
  background-size: 1em;
  background-repeat: no-repeat;
  background-position: center;
}

.listico {
  background-image: url("../../assets/list.svg");
}

.tilesico {
  background-image: url("../../assets/tiles.svg");
}

.leftico {
  margin: 0;
  background-image: url("../../assets/left.svg");
}

.rightico {
  margin: 0;
  background-image: url("../../assets/right.svg");
}

.searchholder {
  width: 12em;
  margin-left: 0.25em;
  position: relative;
  height: 1.8em;
}

.search {
  font-size: 0.9em;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  border: none;
  padding-right: 1.8em;
  padding-left: 0.5em;
}

.searchbutton {
  position: absolute;
  right: 0;
  top: 0;
  height: 1.4em;
  width: 1.4em;
  margin-top: 0.2em;
  background-image: url("../../assets/search.svg");
  background-position: center;
  background-size: 1.4em;
  background-repeat: no-repeat;
}

.select {
  border: 1px solid #ced4da;
  background-color: white;
  height: 1.8em;
  cursor: pointer;
  margin-right: 0.25em;
}

.realselect {
  border: none;
  background-color: white;
  height: 100%;
}

.pageinput {
  border: none;
  width: 2.5em;
  font-size: 0.9em;
}

textarea:focus,
input:focus {
  outline: none;
}
</style>
