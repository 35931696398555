<template>
  <div class="producttile flexcolumn">
    <a target="_blank"
      ><img
        class="image pointer"
        :src="image"
        :alt="naziv"
        @click="openDetails"
    /></a>
    <div
      class="naziv"
      v-text="naziv + naziv"
      :title="naziv"
      @click="openDetails"
    />
    <div class="flexrow" @click="openDetails">
      <div class="small mutedtext">Kod:</div>
      <div class="space mutedtext" v-text="kod" />
    </div>
    <!-- <div class="flexrow">
      <div class="small mutedtext">EAN:</div>
      <div class="space mutedtext" v-text="ean" />
    </div> -->
    <div class="flexrow">
      Veleprodajna cena
      <div class="space" v-text="nominalna.toFixed(2)" />
    </div>
    <div v-if="user" class="flexrow" style="font-weight: bold">
      Vaša cena bez pdv-a
      <div class="space" v-text="neto.toFixed(2)" />
    </div>
    <div class="flexrow">
      {{ user ? "Vaša cena sa pdv-om" : "Cena sa pdv-om" }}
      <div class="space" v-text="bruto.toFixed(2)" />
    </div>
    <!-- <template v-if="user"> -->
      <div class="flexrow dostupnost">
        Dostupnost:
        <div
          class="space"
          :class="[dostupnost.replace(' ', '')]"
          v-text="dostupnost.toUpperCase()"
        />
      </div>
      <Cart :id="kod" />
    <!-- </template> -->
  </div>
</template>

<script>
import axios from "../../config/axios";
import autobus from "../../config/autobus";
import Cart from "../Cart";

export default {
  name: "ProductTile",
  props: {
    naziv: String,
    kod: String,
    kolicina: Number,
    kriticnakolicina: Number,
    nominalna: Number,
    neto: Number,
    bruto: Number,
    brSlika: Number,
  },
  components: { Cart },
  data() {
    return {
      image: "",
    };
  },
  created() {
    this.image = axios.defaults.baseURL + "/GetImage?ARTIKAL=" + this.kod;
  },
  methods: {
    openDetails() {
      autobus.$emit("modal", {
        modal: "productDetails",
        id: this.kod,
        name: this.naziv,
        brSlika: this.brSlika,
        price: {
          nominalna: this.nominalna,
          neto: this.neto,
          bruto: this.bruto,
        },
      });
    },
    search() {
      this.display = d;
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    dostupnost() {
      if (this.kolicina && this.kolicina > this.kriticnakolicina)
        return "dostupno";
      else if (this.kolicina > 0) return "niske zalihe";
      return "nedostupno";
    },
  },
};
</script>

<style scoped>
.producttile {
  width: 14em;
  margin: 0.5em;
  border-bottom: 1px solid #e0e0e0;
}

.naziv {
  font-weight: bold;
  overflow: hidden;
  height: 2.25em;
  margin: 0.5em 0 1em 0;
}

.dostupnost {
  margin: 0.5em 0 2em 0;
}

.image {
  height: 14em;
  width: 14em;
}

.niskezalihe {
  font-weight: bold;
  color: #c96417;
}

.dostupno {
  font-weight: bold;
  color: green;
}

.nedostupno {
  font-weight: bold;
  color: red;
}

.space {
  margin-left: 0.5em;
}

.small {
  font-size: 0.7em;
  margin-top: auto;
}

.mutedtext {
  color: #6c757d;
}

.border {
  border: 1px solid #ced4da;
  width: 4em;
  height: 1.8em;
  margin: 0.75em 0 1em 0;
}

.amount {
  max-width: 4em;
  height: 1.8em;
  border: none;
  /* padding-right: 1em; */
}

.down {
  transform: scale(1, -1);
}

/* .arrows {
  margin-left: 0em;
} */

.cart {
  height: 1.8em;
  width: 1.8em;
  background-image: url("../../assets/cart.svg");
  background-size: 110%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 0.4em;
  margin-left: 0.5em;
}

textarea:focus,
input:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
