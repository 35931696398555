<template>
  <tr class="bottomborder">
    <td class="pointer" @click="openDetails">
      <a target="_blank"><img class="image" :src="image" :alt="naziv" /></a>
    </td>
    <td class="pointer" @click="openDetails">
      <div class="naziv" v-text="naziv" :title="naziv" />
      <div class="flexrow">
        <div class="small mutedtext">Kod:</div>
        <div class="space mutedtext" v-text="kod" />
      </div>
      <!-- <div class="flexrow">
        <div class="small mutedtext">EAN:</div>
        <div class="space mutedtext" v-text="ean" />
      </div> -->
    </td>
    <td>
      <div class="flexrow">
        Veleprodajna cena
        <div class="space" v-text="nominalna.toFixed(2)" />
      </div>
      <div class="flexrow" style="font-weight: bold" v-if="user">
        Vaša cena bez pdv-a
        <div class="space" v-text="neto.toFixed(2)" />
      </div>
      <div class="flexrow">
        {{ user ? "Vaša cena sa pdv-om" : "Cena sa pdv-om" }}
        <div class="space" v-text="bruto.toFixed(2)" />
      </div>
    </td>
    <!-- <template v-if="user"> -->
      <td>
        <div
          class="space"
          :class="[dostupnost.replace(' ', '')]"
          v-text="dostupnost.toUpperCase()"
        />
      </td>
      <td>
        <Cart :id="kod" />
      </td>
      <td style="position: relative">
        <div :class="[dostupnost.replace(' ', '') + 'linija']"></div>
      </td>
    <!-- </template> -->
  </tr>
</template>

<script>
import axios from "../../config/axios";
import autobus from "../../config/autobus";
import Cart from "../Cart";

export default {
  name: "ProductList",
  props: {
    naziv: String,
    kod: String,
    ean: Number,
    kolicina: Number,
    kriticnakolicina: Number,
    nominalna: Number,
    neto: Number,
    bruto: Number,
    brSlika: Number,
  },
  components: {Cart},
  data() {
    return {
      image: "",
    };
  },
  created() {
    this.image = axios.defaults.baseURL + "/GetImage?ARTIKAL=" + this.kod;
  },
  methods: {
    openDetails() {
      autobus.$emit("modal", {
        modal: "productDetails",
        id: this.kod,
        name: this.naziv,
        brSlika: this.brSlika,
        price: {
          nominalna: this.nominalna,
          neto: this.neto,
          bruto: this.bruto,
        },
      });
    },
    search() {
      this.display = d;
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    dostupnost() {
      if (this.kolicina && this.kolicina > this.kriticnakolicina)
        return "dostupno";
      else if (this.kolicina > 0) return "niske zalihe";
      return "nedostupno";
    },
  },
};
</script>

<style scoped>
.bottomborder {
  border-bottom: 1px solid #e0e0e0;
}

.naziv {
  font-size: 1em;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 20em;
  overflow: hidden;
}

.image {
  height: 5em;
  width: 5em;
}

.dostupno {
  font-weight: bold;
  color: green;
}

.nedostupno {
  font-weight: bold;
  color: red;
}

.niskezalihe {
  font-weight: bold;
  color: #c96417;
}

.dostupnolinija {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #badb99;
  width: 0.4em;
  height: 100%;
}

.nedostupnolinija {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #ff3737;
  width: 0.4em;
  height: 100%;
}

.niskezalihelinija {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #c96417;
  width: 0.4em;
  height: 100%;
}

.space {
  margin-left: 0.5em;
}

.small {
  font-size: 0.7em;
  margin-top: auto;
  margin-bottom: 0.2em;
}

.mutedtext {
  color: #6c757d;
}

.border {
  border: 1px solid #ced4da;
  width: 4em;
  height: 1.8em;
  margin: 0.75em 0 1em 0;
}

.amount {
  width: min-content;
  max-width: 3.1em;
  height: 1.8em;
  border: none;
  padding-right: 1.5em;
}

.up {
  font-size: 0.85em;
  margin-left: -1em;
}

.down {
  font-size: 0.85em;
  margin-left: -1em;
  transform: scale(1, -1);
}

.cart {
  height: 1.8em;
  width: 1.8em;
  background-image: url("../../assets/cart.svg");
  background-size: 110%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 0.4em;
  margin-left: 0.5em;
}

textarea:focus,
input:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
