<template>
  <div :style="{ marginLeft: indent + 'em' }">
    <div class="flexrow" @click="$store.state.category = category.id">
      <div style="flex: 1">
        <div
          :class="{ pickedcategory: currentCategory === category.id }"
          class="catentry"
        >
          <b v-if="category.children.length" style="border: groove">{{
            !picked ? "+ " : "- "
          }}</b
          >{{ category.name }}
        </div>
      </div>
      <div style="margin-top: auto">
        <div
          :class="{ pickedcategory: currentCategory === category.id }"
          class="catentry"
        >
          <div
            v-if="!category.children.length"
            style="color:#6c757d;font-size=0.8em;white-space:pre;"
          >
            {{ " " + category.brArt }}
          </div>
        </div>
      </div>
    </div>
    <div class="flexcolumn" v-if="picked">
      <Category
        v-for="cat in category.children"
        :key="cat.id"
        :indent="indent + 0.5"
        :category="cat"
      />
    </div>
  </div>
</template>

<script>
import autobus from "../config/autobus";
import Category from "./Category";

export default {
  name: "Category",
  components: { Category },
  props: { category: Object, indent: Number },
  computed: {
    currentCategory() {
      return this.$store.state.category;
    },
    picked() {
      const children = [];
      const myChildren = (c) => {
        children.push(c.id);
        c.children.forEach((x) => myChildren(x));
      };
      myChildren(this.category);
      return children.includes(this.currentCategory);
    },
  },
};
</script>

<style scoped>
.catentry {
  font-size: 0.8em;
  line-height: 1.8;
  display: block;
  border-bottom: 1px solid #eef1f3;
  cursor: pointer;
}

.pickedcategory {
  font-weight: bold;
}
</style>