<template>
  <div class="flexrow acenter jcenter">
    <div class="border flexrow" style="width: 4.5em">
      <input v-model="cart" type="number" min="1" class="amount" />
      <div class="arrows flexcolumn acenter jcenter">
        <div class="up pointer" @click="cartNumber(true)">▲</div>
        <div class="down pointer" @click="cartNumber(false)">▲</div>
      </div>
    </div>
    <div @click="addToCart" class="cart pointer" />
  </div>
</template>

<script>
import axios from "../config/axios";
import autobus from "../config/autobus";

export default {
  name: "Cart",
  props: { id: String },
  data() {
    return {
      cart: 1,
    };
  },
  methods: {
    cartNumber(increment) {
      if (increment) {
        if (this.cart !== "") this.cart = parseInt(this.cart) + 1;
        else this.cart = 1;
      } else {
        if (this.cart !== "") this.cart = parseInt(this.cart) - 1;
        if (this.cart === 0) this.cart = "";
      }
    },
    async addToCart() {
      if (!this.$store.state.user) {
        autobus.$emit("modal", {
          modal: "signin",
          onSignin: async (ATOKEN) => {
            let params = {
              ATOKEN,
              ARTIKAL: this.id,
              KOLICINA: this.cart,
            };
            const res = (await axios.post("AddToCart", null, { params })).data;
            this.$store.state.cartCount = res.BROJ_ARTIKALA_U_KORPI;
            sessionStorage.setItem(
              "BROJ_ARTIKALA_U_KORPI",
              res.BROJ_ARTIKALA_U_KORPI
            );
            if (res.DODATA_KOLICINA != this.cart) {
              alert(
                `U korpu je dodato ${parseInt(res.DODATA_KOLICINA)}, jer ${
                  this.cart
                } nije raspoloživo.`
              );
            } else alert(`U korpu je dodato ${parseInt(res.DODATA_KOLICINA)}.`);
          },
        });
        return;
      }
      let params = {
        ATOKEN: this.$store.state.user.SESSION_TOKEN,
        ARTIKAL: this.id,
        KOLICINA: this.cart,
      };
      const res = (await axios.post("AddToCart", null, { params })).data;
      this.$store.state.cartCount = res.BROJ_ARTIKALA_U_KORPI;
      sessionStorage.setItem(
        "BROJ_ARTIKALA_U_KORPI",
        res.BROJ_ARTIKALA_U_KORPI
      );
      if (res.DODATA_KOLICINA != this.cart) {
        alert(
          `U korpu je dodato ${parseInt(res.DODATA_KOLICINA)}, jer ${
            this.cart
          } nije raspoloživo.`
        );
      } else alert(`U korpu je dodato ${parseInt(res.DODATA_KOLICINA)}.`);
      this.cart = parseInt(res.DODATA_KOLICINA);
    },
  },
};
</script>

<style scoped>
.border {
  border: 1px solid #ced4da;
  width: 4em;
  height: 1.8em;
  margin: 0.75em 0 1em 0;
}

.amount {
  max-width: 4em;
  height: 1.8em;
  border: none;
  /* padding-right: 1em; */
}

.down {
  transform: scale(1, -1);
}

.cart {
  height: 1.8em;
  width: 1.8em;
  background-image: url("../assets/cartAdd.svg");
  background-size: 110%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 0.4em;
  margin-left: 0.5em;
}

textarea:focus,
input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>