import axios from 'axios';
import autobus from './autobus';

// const ax = axios.create({ baseURL: 'https://b2b.centarmilutinovic.com/WebShopRest', withCredentials: true });
const ax = axios.create({ baseURL: 'WebShopRest', withCredentials: true });
// if (process.env.NODE_ENV === "development")
//   ax.defaults.baseURL = "https://localhost:8081"
ax.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    autobus.$emit("signout", true);
  }
  return Promise.reject(error);
});

export default ax;
