<template>
  <div class="myfooter flexcolumn jcenter between">
    <div>Adresa: Kralja Milutina 22B 5/1, Šabac</div>
    <div>Telefon: 015 334 736</div>
    <router-link class="link" :to="{name: 'Products'}">Načini isporuke</router-link>
    <router-link class="link" :to="{name: 'Products'}">Načini plaćanja</router-link>
    <router-link class="link" :to="{name: 'Products'}">Opšti uslovi kupovine</router-link>
    <div>
      Sajt izradio Digipro ©2021 | Centar Milutinović, doo. Sva prava zadržana.
    </div>
  </div>
</template>

<script>
import autobus from "../config/autobus";

export default {
  name: "MyFooter",
  methods: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.myfooter {
  background-color: #e05c0b;
  color: white;
  font-size: 1em;
  padding: 1em;
}
</style>
